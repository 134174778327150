import 'ace-builds/src-noconflict/ace';
// import Antlr4Worker from './antlr4-worker';
// import {DocumentSearchQueryLexer} from '@/generated-js/DocumentSearchQueryLexer';
// import {DocumentSearchQueryParser} from '@/generated-js/DocumentSearchQueryParser';

"use strict";

ace.define('ace/mode/teqviser-search_query-highlight-rules', [
    'require', 'exports', 'module',
    'ace/lib/oop', 'ace/mode/text_highlight_rules'
], function(acequire, exports, module) {

    const oop = acequire('../lib/oop');
    const TextHighlightRules = acequire('./text_highlight_rules').TextHighlightRules;

    function TeqviserSearchQueryHighlightRules() {
        const keywords = "AND|OR|DIST|NOT";

        var keywordMapper = this.createKeywordMapper({
            "keyword": keywords
        }, "identifier", true);

        this.$rules = {
            "start" : [ {
                token : "string",           // " string
                regex : '".*?"'
            }, {
                token : "string",           // ' string
                regex : "'.*?'"
            }, {
                token : "string",           // ` string (apache drill)
                regex : "`.*?`"
            }, {
                token : "constant.numeric", // float
                regex : "[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b"
            }, {
                token : keywordMapper,
                regex : "[a-zA-Z_$][a-zA-Z0-9_$]*\\b"
            }, {
                token : "paren.lparen",
                regex : "[\\(]"
            }, {
                token : "paren.rparen",
                regex : "[\\)]"
            }, {
                token : "text",
                regex : "\\s+"
            } ]
        };
        this.normalizeRules();
    }

    oop.inherits(TeqviserSearchQueryHighlightRules, TextHighlightRules);

    exports.TeqviserSearchQueryHighlightRules = TeqviserSearchQueryHighlightRules;
});

ace.define('ace/mode/teqviser-search_query', [
    'require', 'exports', 'module',
    'ace/lib/oop', 'ace/mode/text', 'ace/mode/behaviour/cstyle', 'ace/mode/teqviser-search_query-highlight-rules',
    'ace/editor', 'ace/config','ace/ext/language_tools'
], function(acequire, exports, module) {

    const oop = acequire('../lib/oop');
    const text = acequire('./text');
    const TeqviserSearchQueryHighlightRules = acequire('./teqviser-search_query-highlight-rules').TeqviserSearchQueryHighlightRules;
    const CstyleBehaviour = acequire('ace/mode/behaviour/cstyle').CstyleBehaviour;

    function SearchQueryMode() {
        this.HighlightRules = TeqviserSearchQueryHighlightRules;
        this.$behaviour = this.$defaultBehaviour;
    }

    oop.inherits(SearchQueryMode, text.Mode);

    (function() {
        this.$id = "ace/mode/teqviser-search_query";

        this.$defaultBehaviour = new CstyleBehaviour({braces:true});

        // this.createWorker = function(session) {
        //     return new Antlr4Worker(session, DocumentSearchQueryLexer, DocumentSearchQueryParser);
        // };

    }).call(SearchQueryMode.prototype);

    exports.Mode = SearchQueryMode;
});

ace.define('ace/theme/teqviser-search_query',['require', 'exports', 'module', 'ace/lib/dom', 'ace/theme/chrome'], function(acequire, exports, module) {
    const baseStyle = acequire('ace/theme/chrome');
    const baseClass = baseStyle.cssClass;

    exports.isDark = !!baseStyle.isDark;
    exports.cssClass = 'ace-teqviser-search_query ' + baseClass;
    exports.cssText = `
        .ace-teqviser-search_query *[class*=in-quote] {
            background-color: #f1d357;
        }
        .ace-teqviser-search_query.${baseClass} {
          font-weight: lighter;
          font-style: normal;
          font-size: 13px;
        }
        .ace-teqviser-search_query.${baseClass} .ace_line .ace_string {
          color: #4848dd;
        }
        .ace-teqviser-search_query.${baseClass} .ace_line .ace_keyword {
          color: #db6100;
        }
        .ace-teqviser-search_query.${baseClass} .ace_marker-layer .ace_bracket {
          margin: -1px 0 0 -1px;
          border: 2px solid #ff0000;
          background: #fed115;
        }
    `;

    const dom = acequire("ace/lib/dom");
    dom.importCssString(exports.cssText, exports.cssClass);
});
