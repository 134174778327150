<template>
    <div class="iqp-aceEditor">
        <div class="ace_container_wrap" :class="classes">
            <div ref="ace_container"></div>
            <div class="controls">
                <el-button type="text" icon="el-icon-close" v-show="cleanBtnVisible" @click="cleanEditor"></el-button>
            </div>
            <div class="buttons-bar" v-if="buttonsBar">
                <button v-for="btn in buttons" :key="btn.value" type="button" @click="handleButtons(btn)">{{btn.text}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/theme-chrome';
import './mode/search_query';

export default {
    name: 'AceEditor',
    props: {
        value: {
            type: String,
            required: true
        },
        lang: String,
        theme: String,
        width: { // auto|12px|100%
            type: String,
            default:'auto'
        },
        height: { // auto|12px|100%
            type: String,
            default:'68px'
        },
        autoHeight: {
            type: Boolean,
            default: true
        },
        options: Object,
        commands: Array,
        classes: {
            type: Object,
            default: () => ({})
        },
        buttonsBar: {
            type: Boolean,
            default: true
        },
        buttons: {
            type: Array,
            default() {
                /*
                {
                    value: '%',
                    text: '%',
                    cursorOffset: 0
                }
                 */
                return [];
            }
        }
    },
    data() {
        return {
            contentBackup: '',
            cleanBtnVisible: false
        }
    },
    watch: {
        value(val) {
            if(this.contentBackup !== val){
                this.editor.session.setValue(val,1);
                this.contentBackup = val;
            }
            this.cleanBtnVisible = !!val;
            this.updateSize();
        },
        theme(newTheme) {
            this.editor.setTheme('ace/theme/'+newTheme);
        },
        lang(newLang) {
            this.editor.getSession().setMode('ace/mode/'+newLang);
        },
        options(newOption) {
            this.editor.setOptions(newOption);
            this.editor.textInput.setReadOnly(newOption.readOnly);
        },
        height(val) {
            this.updateSize();
        },
        width(newVal) {
            this.updateSize();
        }
    },
    methods: {
        px(n) {
            if(!n || n === 'auto') {
                return;
            }
            if( /^\d*$/.test(n) ){
                return n+"px";
            }
            return n;
        },
        updateSize() {
            const vm = this;
            const autoHeight = this.autoHeight;
            vm.editor.setOptions({
                wrap: autoHeight,
                autoScrollEditorIntoView: autoHeight,
                maxLines: autoHeight ? Infinity : null
            });
            // vm.editor.getSession().setUseWrapMode(this.autoHeight);
            // vm.editor.setAutoScrollEditorIntoView(this.autoHeight);
            // vm.$nextTick(function(){
            //     vm.editor.resize();
            // });
        },
        cleanEditor() {
            this.editor.setValue('');
        },
        handleButtons(button) {
            if (this.options.readOnly || !button || !button.value || button.value.length === 0) {
                return;
            }
            const ed = this.editor;
            const session = ed.session;

            ed.focus();

            const position = ed.getCursorPosition();
            const text = session.getLine(position.row);

            let value = button.value;
            let offset = button.cursorOffset || 0;
            if(position.column > 0 && text[position.column - 1] !== ' ') {
                value = ' ' + value;
            }
            if(position.column < text.length && text[position.column] !== ' ') {
                value = value + ' ';
                offset && offset++;
            }

            session.insert(position, value);
            offset && ed.navigateLeft(offset);
        },
        handleHelp() {
            utils.window.openInNewWindow({url: `${this.$store.state.app.root}/help/ms_15_query_language.html#query-language`});
        }
    },
    beforeDestroy() {
        this.editor.destroy();
        this.editor.container.remove();
    },
    mounted() {
        const vm = this,
            lang = this.lang || 'text',
            editor = vm.editor = ace.edit(vm.$refs.ace_container),
            value = this.value;

        // editor.focus();
        editor.$blockScrolling = Infinity;
        editor.setOptions({
            theme: 'ace/theme/'+(this.theme || 'chrome'),
            mode: 'ace/mode/'+lang,
            fontSize: 14,
            minLines: 1,
            showPrintMargin: false,
            showLineNumbers: false,
            indentedSoftWrap: false,
            highlightActiveLine: false
        });

        editor.setValue(value, 1);
        editor.renderer.setScrollMargin(15, 15, 15, 15);

        this.contentBackup = value;
        this.cleanBtnVisible = !!value;

        editor.on('change', function () {
            const content = editor.getValue();
            vm.$emit('input',content);
            vm.contentBackup = content;
        });

        this.updateSize();

        if(vm.options) {
            editor.setOptions(vm.options);
        }

        if (this.commands) {
            this.commands.forEach(command => {
                editor.commands.addCommand(command);
            });
        }

        this.$emit('init', editor);
    },

}
</script>

<style lang="scss">
.iqp-aceEditor {
    .ace_container_wrap {
        position: relative;
        padding-right: 65px;
        margin-bottom: 25px;
        border: 1px solid $iq-border-color-base;
        border-radius: 5px 5px 0 0;

        .ace_editor{
            border-radius: 5px 5px 0 0;
        }

        &.has_error {
            .ace_editor {
                border-color: $iq-color-danger;
            }
        }
        .controls {
            position: absolute;
            top: 6px;
            right: 11px;

            .el-button {
                font-size: 16px;
            }
        }
        .buttons-bar {
            position: absolute;
            border-top: none;
            border-radius: 0 0 5px 5px;
            background: $iq-color-blue;
            display: flex;
            line-height: 1;
            font-size: 13px;
            font-weight: 500;
            font-variant: all-small-caps;
            box-sizing: border-box;
            width: 100%;
            > button {
                cursor: pointer;
                padding: 3px 15px 4px;
                background: transparent;
                border: none;
                border-right: 1px solid $iq-border-color-base;
                //color: $iq-color-blue;
                color: $iq-color-blue-light-ultra;

                &:hover {
                    background: lighten($iq-color-blue, 5%);
                }

                &:last-of-type {
                    border-right: none;
                }
            }
        }
    }
}
</style>
