import AceEditor from './index'

export default {
    name: 'SearchQueryEditor',
    mixins: [AceEditor],
    props: {
        lang: {
            default: 'teqviser-search_query'
        },
        theme: {
            default: 'teqviser-search_query'
        },
        options: {
            type: Object,
            default() {
                return { minLines: 1, maxLines: 20, showLineNumbers: false, showGutter: false};
            }
        },
        buttons: {
            type: Array,
            default() {
                return [
                    {text: 'AND', value: 'AND'},
                    {text: 'OR', value: 'OR'},
                    {text: 'NOT', value: 'NOT'},
                    {text: '" "', value: '""', cursorOffset: 1},
                    {text: '( )', value: '()', cursorOffset: 1},
                    {text: '( ) DIST 5', value: '() DIST 5', cursorOffset: 8},
                    {text: '@', value: '@'}
                ];
            }
        }
    }
};
